import React from "react";
import styled from "@emotion/styled";

import Link from "../Link";
import KulkiSvg from "./KulkiSvg"

import PlayIcon from "../../assets/img/play.svg";
import Dwukropek from "../../assets/img/natemat/dots_orange.svg";
import CommentImage from "../../assets/img/comment_gray.svg";
import FacebookImage from "../../assets/img/facebook_round_gray.svg";

import Media from "./Media"

const PhotoOnLeft = props => {
  const { article, handleClick = () => { } } = props;
  const imageSize = (props.size == 'lg' && window.innerWidth > 992 ? 246 : 88);
  //5805
  const imageHeight = props.size == 'lg' && window.innerWidth > 992 ? 267 : 110
  const partner = (article.partner ? <Partner>{article.partner}</Partner> : null);
  const label = (article.label ? <Label className="hidden-xs">{article.label}</Label> : null);
  const player = (parseInt(article.player) === 1 ? <Player /> : null);
  const imgUrl = (article.author?.url?.includes("blogi") || !article.picture?.img) ? article.author.img : article.picture.img;

  var stats = [];
  if (article.stats) {
    stats.push(<Likes key='shares'><img src={FacebookImage} alt="" /><span data-facebook-stats> {article.stats.shares}</span></Likes>);
    stats.push(<Likes key='comments'><img src={CommentImage} alt="" /><span data-comments-stats> {article.stats.comments}</span></Likes>);
  }

  return (
    <BoxWrapper onClick={handleClick} hasMobilePcs={props.article.hasMobilePcs} hasDesktopPcs={props.article.hasDesktopPcs} className={('PhotoOnLeft__size--' + props.size) + " " + (article.background ? 'background--' + article.background : null) + " photo-on-left"}  >
      <Link to={article.url}>
        <span>
          <ImageWrapper>
            <Media
              media={props.media}
              src={imgUrl}
              preserveRatio={false}
              aspectRatio={props.size == 'lg' && window.innerWidth > 992 ? 0.92 : 0.8}
              width={imageSize}
              height={imageHeight} />
            {player}
            {partner}
            {label}
          </ImageWrapper>
          {/* <Stats data-stat-furl={article.url} >{stats}</Stats> */}
        </span>
        <Title className="photo-on-left__title" hasMobilePcs={props.article.hasMobilePcs} hasDesktopPcs={props.article.hasDesktopPcs}>
          <KulkiSvg />
          {article.title}
        </Title>
      </Link>
    </BoxWrapper>
  );
};

const Title = styled.span`
  color: ${({ hasMobilePcs }) => hasMobilePcs ? '#fff' : '#272424'};
  font-size: 14.1px;
  padding: 5px 5px 5px 30px;
  /* margin-left: 25px;   */
  display: block;
  position: relative;
  overflow: hidden;
  max-height: 100px;

  > svg {
    /* content: ""; */
    display: block;
    position: absolute;
    top: 0;
    left: 10px;
    /* content: url(${Dwukropek}); */
    /* background-size: cover; */
    /* background: ${props => props.theme.secondColor}; */
  }
  @media (min-width: 992px) {
    color: ${({ hasDesktopPcs }) => hasDesktopPcs ? '#fff' : '#272424'};
    background-color: ${({ hasDesktopPcs }) => hasDesktopPcs && 'transparent'};
  }
`;

const ImageWrapper = styled.span`
  display: block;
  position: relative;
  margin-left: -1px;
  margin-top: -1px;
  margin-bottom: -1px;
  img {
    width: 88px;
    height: 110px;
  }
`;

const Partner = styled.span`
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0px 8px;
  background-color: #D8D8D8;
	color: #8B8B8B;
  line-height: 16px;
  font-size: 11px;
  z-index: 4;
`;

const Label = styled.span`
  display: block;
  position: absolute;
  left: 30px;
  top: 30px;
  padding: 0px 10px;
  background: ${props => props.theme.mainColor};
  color: white;
  line-height: 22px;
  font-size: 15px;
  font-weight: 500;
`;

const Player = styled.span`
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: transparent url(${PlayIcon}) no-repeat center center;
  background-size: 60px auto;
  z-index: 5;
`;

const Stats = styled.span`
  display: block;
  white-space: nowrap;
`;

const Likes = styled.span`
  display: inline-block;
  padding: 0 0 0 6px;
  color: #7b7b7b;
  font-size: 10px;
  /* margin-right: 6px; */
  img {
    width: 12px;
    padding-right: 2px;
  }
`;

const BoxWrapper = styled.div`
  padding: 8px 0;
  border-radius: 4px;
  & > a {
    color: #272424;
    border: 1px solid rgba(151,151,151,0.15);
    background-color: ${({ hasMobilePcs }) => hasMobilePcs ? 'rgba(1, 1, 1, 0.2)' : '#ffffff'};
    text-decoration: none;
    display: flex;
    justify-content: start;
    position: relative;
     /* kreska pionowa */
    &:after{
        display: block;
        position: absolute;
        top: -1px;
        right: -4px;
        content: '';
        width: 4px;
        height: 0;
        background-color: ${props => props.theme.mainColor};
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        transition: all 0.2s linear;
        transition-delay: 0s;
    }
    &:hover {
      color: ${props => props.theme.mainColor};
      text-decoration: none;
      box-shadow: 4px 4px 10px 0px rgba(0,0,0,0.3);
      cursor: pointer;
      border-bottom: 1px solid transparent;
      &:after{
        height: calc(100% + 2px);
      }

      circle {
        fill: #000;
        stroke: #000;
      }
    }

    circle {
      transition: all .2s linear;
    }
  }

  /* opcja z dużym tekstem, wykorzystywana w "dwójka" */
  @media (min-width: 992px) {
    padding: 12px 0;
    & > a {
      background-color: ${({ hasDesktopPcs }) => hasDesktopPcs ? 'rgba(1, 1, 1, 0.2)' : '#ffffff'};
    }
    &.PhotoOnLeft__size--lg {
      
      ${ImageWrapper} {
        img {
          width: 246px;
          height: 246px;
        }
      }

      ${Stats} {
        text-align: right;
        padding-right: 20px;
      }

      ${Title} {
        font-size: 24px !important;
        padding-left: 45px;
        max-height: 268px;
        svg {
          width: 20px;
          height: 50px;
          top: -6px;
          left: 13px;
        }
      }
    }
  }

  /* na głównej, opcja czarna i czerwona */
  &.background {
  	&--red {
  		> a {background-color: #ED2024;}
  		${Title} {color: white;}
  	}
  	
  	&--black {
  		> a {background-color: black;}
  		${Title} {color: white;}
  	}
  }
`;

export default PhotoOnLeft;
